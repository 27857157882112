<!-- Product -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Product -->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Product Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <el-form class="form" id="kt_form">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                            

                                <div class="row  mb-10">
                                    <div class="col-xl-12">
                                    <h3 class="font-weight-bold text-dark">
                                        <i class="flaticon-layers"></i> {{title_text}} 
                                    </h3>                                       
                                    </div>                             
 
                                </div>
                                <div class="row  mb-10">
                                    <div class="col-xl-12">
                                    <h2 class="font-weight-bold text-dark">
                                        {{form.product_list? form.product_list.name: ''}} 
                                    </h2>                                       
                                    </div>                          
 
                                </div>
                                <div class="row">
                                    <div class="col-xl-6"> 
                                         <div class="row">                                             
                                            <div class="col-xl-12">
                                                <div class="row">                                                    
                                                    <div class="col-xl-8">
                                                         <el-form-item label="Stock Quantity" prop="unit_no">
                                                            <el-input-number v-model="form.stock_quantity" auto-complete="nope"  :class="form.errors.has('stock_quantity') ? 'error required':'required'"></el-input-number>                                                            
                                                        </el-form-item>                                                       
                                                    </div>

                                                    <div class="col-xl-8">
                                                        <el-form-item label="Stock Status" >                                    
                                                            <el-select filterable v-model="form.stock_status" placeholder="Select" style="width:100%" >
                                                                 <el-option value="null" >Select Status</el-option>
                                                                <el-option value="instock">In Stock</el-option>
                                                                <el-option value="outofstock">Out of Stock</el-option>
                                                                <el-option value="backorder">Back Order</el-option>
                                                            </el-select>  
                                                        </el-form-item>                                                                                                           
                                                    </div>
                                                    <div class="col-xl-8">
                                                         <el-form-item label="Minimum Order Quantity" prop="unit_no">
                                                            <el-input-number v-model="form.low_quantity_threshold" auto-complete="nope"  ></el-input-number>                                                            
                                                        </el-form-item> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                               
                                    </div>               
                                    <div class="col-xl-6">
                                    <div class="form-group">
                                        <div class="field text-center">
                                            <div class="preview-container">                                              
                                                <span class="index-image employee-image" v-loadimage="form.product_list.images?form.product_list.images:''"></span>                                           
                                            </div>                     
                                        </div>                                                    
                                    </div>
                                    </div>
                                                    
                                </div>
                               
                            
                            </div>

                            <!--begin: Product Actions -->
                            <div class="d-flex justify-content-between">
                                <a class="text-white" @click="updateInventory">
                                    <div class="btn btn-primary font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>

                            <!--end: Product Actions -->
                        </el-form>
                        <!--end: Product Form-->
                    </div>
                </div>
                <!--end: Product Body-->
            </div>
        <v-dialog/>
        </div>
        <!--end: Product-->
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import {getProduct} from "@/api/inventory";
export default {
    name: 'update-inventory',
    data(){
        return{
            title_text : 'Update Product',
            images: '/ctshub/media/bg/profile-img.png',
            api_url :'invproduct/edit/',
            form : new Form({
                stock_quantity : null,
                stock_status : null,
                low_quantity_threshold : null,
                images : null


            })

        }
    },
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventory Management", route: "/inventory/product" },
      { title: "Add/Edit Product" }]);

    },
    methods: {
        updateInventory: function() {
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){
            this.$router.push({ path: '/inventory/product' })
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      })
    }, 

    },
    created(){
    
    let product_id = this.$route.params.product_id;

    this.logo_btn_text = 'Add Logo'
    this.crstatus='1'

    if(parseInt(product_id) > 0){
      this.logo_btn_text = 'Update Logo'
      this.submit_btn_text = 'Update Product'
      this.title_text = 'Update Product'
      this.action = 'update'
      this.response_msg = 'Product updated successfully.'
      this.upstatus='1'
      this.loading = true
      getProduct(this.$route.params.product_id).then(response => {  
                  
          if(response.data.data.inventory_id){
          this.api_url = 'invproduct/edit/' + response.data.data.inventory_id
        }        
         this.form = new Form(response.data.data)
        if(response.data.data.images){
          this.images = process.env.VUE_APP_BASE_IMAGE_URL + response.data.data.images
        }        
        this.loading = false
      })
    }
  }
}
</script>
